<template>
  <BaseLayout>
    <div class="card card-fullscreen">
      <div class="card-body">
        <div class="row gy-sm-1">
          <div class="col-md-3">
            <div class="input-group input-group-merge shadow-none">
              <input type="text" class="form-control border-1 py-2" placeholder="Поиск по названию"
                     v-model="searchData.name" @change="search">
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-group input-group-merge shadow-none">
              <input type="text" class="form-control border-1 py-2" placeholder="Поиск по бренду"
                     v-model="searchData.brand" @change="search">
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-group input-group-merge shadow-none">
              <select class="form-select" v-model="activeStores" multiple @change="setActiveStores">
                <template v-for="(store, index) in stores" :key="index">
                  <option :value="store.id">{{ store.name }}</option>
                </template>
              </select>
            </div>
          </div>
        </div>
        <div v-if="!products.length" class="mt-7">
          Товары не найдены
        </div>
      </div>
      <div style="height: 100%">
        <ag-grid-vue ref="pricesGrid"
                     :rowData="rowData"
                     :columnDefs="colDefs"
                     :onCellValueChanged="onCellValueChanged"
                     :tooltipInteraction="true"
                     :tooltipShowDelay="0"
                     :getRowId="getRowId"
                     :gridOptions="gridOptions"
                     @grid-ready="onGridReady"
                     domLayout="autoHeight"
                     style="width: 100%;"
                     class="ag-theme-quartz"
        >
        </ag-grid-vue>

        <BasePagination :info="paginationInfo" ref="productsPagination" @changePage="changePage">
        </BasePagination>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue'
import {PageInfo} from "@/data/page";
import {user} from "@/data/user";
import {stores} from "@/data/store";
import {getProducts, updateCosts, updateMinPrices} from "@/api/product";
import BasePagination from "@/components/list/Pagination.vue"
import MarketplaceTooltip from "@/components/tooltips/Marketplace.vue"

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import {AgGridVue} from "ag-grid-vue3";
import {getOffers, setOffers} from "@/api/offer";
import {getChangeInfo, isEditable} from "@/helpers/price";

export default {
  name: 'PricesView',
  // eslint-disable-next-line vue/no-unused-components
  components: {BaseLayout, BasePagination, AgGridVue, MarketplaceTooltip},
  data() {
    return {
      stores: [],
      activeStores: [],
      products: [],
      paginationInfo: {},
      searchData: {
        name: '',
        brand: ''
      },
      rowData: [],
      fixedCols: [
        {field: "name", headerName: 'Товар', width: 500, sortable: false, editable: true},
        {field: "cost", headerName: 'Себестоимость', width: 100, sortable: false, editable: true},
        {field: "min_price", headerName: 'РРЦ', width: 100, sortable: false, editable: true},
      ],
      colDefs: [],
      gridApi: {},
      gridOptions: {
        suppressMovableColumns: true,
      }
    }
  },
  methods: {
    async getProducts() {
      const result = await getProducts(user.active_company, this.$route.query)
      this.paginationInfo = {
        total: result.data.total,
        pages: result.data.pages,
        page: result.data.page,
        size: result.data.size
      }
      this.products = result.data.items
      this.setRows()
      await this.setCols()
    },
    async search() {
      const query = {...this.$route.query}
      delete query.page
      Object.entries(this.searchData).forEach(([key, value]) => {
        if (value) {
          query[key] = value
        } else {
          delete query[key]
        }
      });
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...query
        }
      })

    },
    setSearch() {
      if (this.$route.query.name) {
        this.searchData.name = this.$route.query.name
      } else {
        this.searchData.name = ''
      }
      if (this.$route.query.brand) {
        this.searchData.brand = this.$route.query.brand
      } else {
        this.searchData.brand = ''
      }
    },
    async changePage(page) {
      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: page
        }
      });
    },
    setPageInfo() {
      PageInfo.title = 'Ценообразование'
      PageInfo.breadcrumbs = [
        {
          title: 'Главная',
          link: '/'
        },
        {
          title: PageInfo.title
        }
      ]
    },
    setActiveStores() {
      stores.setActiveStores(this.activeStores)
      this.setRows()
      this.setCols()
    },
    setRows() {
      this.rowData = []
      this.products.forEach((product) => {
        this.rowData.push(
            {
              id: product.id,
              name: product.name,
              cost: product.cost,
              min_price: product.min_price,
              context: {
                code: product.code
              }
            }
        )
      })
    },
    async setCols() {
      this.colDefs = []
      this.colDefs = [...this.fixedCols]
      this.activeStores.forEach((store) => {
        const store_info = stores.stores.find(obj => obj['id'] === store);
        const col_context = {
          marketplace: store_info.marketplace,
          store_id: store_info.id
        }
        this.colDefs.push(
            {
              field: store_info.id, headerName: store_info.name, minWidth: 500,
              children: [
                {
                  field: store_info.id + '_price',
                  headerName: 'Цена',
                  editable: (params) => isEditable(params),
                  sortable: false,
                  width: 100,
                  context: col_context,
                },
                {
                  field: store_info.id + '_profit_amount',
                  headerName: 'Прибыль',
                  editable: (params) => isEditable(params),
                  sortable: false,
                  width: 100,
                  context: col_context,
                },
                {
                  field: store_info.id + '_profit_percent',
                  headerName: 'Процент прибыли',
                  editable: (params) => isEditable(params),
                  sortable: false,
                  width: 100,
                  context: col_context,
                },
                {
                  field: store_info.id + '_commission',
                  headerName: 'Комиссия',
                  sortable: false,
                  width: 100,
                  tooltipComponent: MarketplaceTooltip,
                  tooltipValueGetter: (p) => {
                    return p
                  },
                  context: {
                    store_id: store_info.id,
                    marketplace: store_info.marketplace
                  }
                }
              ]
            }
        )
      })
      await this.loadOffers()
    },
    async loadOffers(products = null) {
      if (!products) {
        products = this.products.map((product) => product.id)
      }
      const company_id = await user.getActiveCompany()
      const offers = await getOffers(company_id, this.activeStores, products)
      offers.data.items.forEach((offer) => this.setOffer(offer))
    },
    setOffer(offer) {
      const row = this.rowData.find(row => offer['product_id'] === row.id)
      row[offer['store_id'] + '_price'] = offer['price']
      row[offer['store_id'] + '_profit_amount'] = offer['profit_info']['profit_amount']
      row[offer['store_id'] + '_profit_percent'] = offer['profit_info']['profit_percent']
      row[offer['store_id'] + '_commission'] = offer['profit_info']['commission_amount']
      row[offer['store_id'] + '_offer_id'] = offer['id']
      row[offer['store_id']] = {
        offer_id: offer['id'],
        store_info: offer['store_info'],
        profit_info: offer['profit_info'],
        store_url: offer['store_url']
      }
      const rowNode = this.gridApi.value.getRowNode(offer['product_id']);
      rowNode.setData(row)
    },
    async onCellValueChanged(e) {
      const change = getChangeInfo(e)
      if (change.what_to_change === 'price' || change.what_to_change === 'profit' || change.what_to_change === 'profit_percent') {
        if (change.info) {
          const offers = [change.info]
          const result = await setOffers(user.active_company, offers)
          result.data.forEach((offer) => this.setOffer(offer))
        }
      }
      if (change.what_to_change === 'cost') {
        const result = await updateCosts(user.active_company, [change.info.data])
        console.log(result)
        await this.loadOffers([change.info.product_id])
      }
      if (change.what_to_change === 'min_price') {
        const result = await updateMinPrices(user.active_company, [change.info.data])
        console.log(result)
      }
    },
    onGridReady(params) {
      this.gridApi.value = params.api;
    },
    getRowId(params) {
      return params.data.id;
    }
  },
  async mounted() {
    this.setPageInfo()
    this.setSearch()
    await user.getUserBase()
    this.stores = await stores.getStores()
    this.activeStores = stores.getActiveStores()
    await this.getProducts()
  },
  watch: {
    '$route'() {
      this.setSearch()
      this.setPageInfo()
      this.getProducts()
    }
  }
}
</script>

<style>
.ag-theme-quartz {
  --ag-header-background-color: white;
  --ag-wrapper-border-radius: 0;
}
.ag-body-viewport{
  min-height: 450px;
}
</style>
