<template>
  <form>
    <div class="col mb-4">
      <label class="form-label">Название магазина WB</label>
      <input type="text" name="name" class="form-control" placeholder="" required v-model="item_data.name">
    </div>
    <input type="hidden" name="company" v-model="item_data.company">
    <input type="hidden" name="id" v-model="item_data.id" v-if="item_data.id">
    <div class="col mb-4">
      <label class="form-label">Модель работы</label>
      <select class="form-control" name="settings[model]" v-model="item_data.settings.model">
        <option value="fbo">FBO</option>
        <option value="fbs">FBS</option>
      </select>
    </div>
    <div class="col mb-4">
      <label class="form-label">Client ID</label>
      <input type="text" name="connection[client_id]" class="form-control" placeholder="" required
             v-model="item_data.connection.client_id">
    </div>
    <div class="col mb-4">
      <label class="form-label">API Key</label>
      <input type="text" name="connection[api_key]" class="form-control" placeholder="" required
             v-model="item_data.connection.api_key">
    </div>
    <div class="row justify-content-end">
        <button type="submit" class="btn btn-primary me-4 waves-effect waves-light">Сохранить</button>
    </div>
  </form>
</template>
<script>
export default {
  name: "OzonForm",
  data() {
    return {
      item_data: {
        id: '',
        name: '',
        company: '',
        marketplace: 'ozon',
        connection: {
          client_id: '',
          api_key: ''
        },
        settings: {
          model: 'fbs'
        }
      }
    }
  },
  props: ['item'],
  mounted() {
    if (this.item) {
      this.item_data = this.item
    }
  }
}
</script>