<template>
  <BaseLayout>
    <template v-slot:top_buttons>
      <div v-click-outside="closeAddButtons">
        <button type="button" class="btn btn-primary" @click="showAddButtons = !showAddButtons">
          Добавить магазин
        </button>
        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1" :class="showAddButtons ? 'show' : ''">
          <a class="dropdown-item" href="javascript:void(0);" @click="createStore('ozon')">Озон</a>
          <a class="dropdown-item" href="javascript:void(0);" @click="createStore('wb')">Wildberries</a>
        </div>
      </div>
    </template>

    <BaseModal ref="storeModal" :modal-title="modalTitle">
      <component v-if="marketplace_form" :is="marketplace_form" v-bind="marketplace_props"
                 ref="MarketPlaceForm"></component>
    </BaseModal>

    <div class="row mb-12 g-6">
      <div class="col-md-6 col-lg-4" v-for="(store, index) in stores" :key="index">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ store.name }}</h5>
            <p class="card-text">Маркетплейс: {{ marketplaces[store.marketplace].title }}</p>
            <div class="demo-inline-spacing">
              <button class="btn btn-outline-secondary waves-effect" @click="editStore(store.marketplace, store.id)">
                Редактировать
              </button>
              <button class="btn btn-outline-danger waves-effect" @click="deleteStore(store.id)">Удалить</button>
            </div>
            <div class="demo-inline-spacing">
              <button type="button" class="btn btn-outline-secondary waves-effect" @click="storeImport(store.id)">
                Импорт товаров
              </button>
              <button type="button" class="btn btn-outline-secondary waves-effect" @click="storeGetOffers(store.id)">
                Загрузка цен из магазина
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/layouts/Base.vue";
import {PageInfo} from "@/data/page";
import {user} from "@/data/user";
import {deleteStore, getStoreOzone, getStores, getStoreWb, importProducts, getOffers} from "@/api/store";
import {VOnClickOutside as ClickOutside} from "@/helpers/directives/clickOutside"
import BaseModal from "@/components/Modal.vue";
import OzonForm from "@/components/forms/Ozon.vue";
import WbForm from "@/components/forms/Wb.vue";
import {shallowRef} from "vue";

const marketplaces = {
  ozon: {
    form: OzonForm,
    api: getStoreOzone,
    title: 'Озон'
  },
  wb: {
    form: WbForm,
    api: getStoreWb,
    title: 'Wildberries'
  }
}

export default {
  name: 'StoresView',
  components: {BaseModal, BaseLayout},
  data() {
    return {
      showAddButtons: false,
      stores: [],
      marketplace_form: '',
      marketplace_props: {},
      modalTitle: 'Новый магазин',
      marketplaces: marketplaces
    }
  },
  directives: {
    ClickOutside
  },
  methods: {
    closeAddButtons() {
      this.showAddButtons = false
    },
    async createStore(marketplace) {
      this.marketplace_form = ''
      this.modalTitle = 'Новый магазин'
      setTimeout(() => {
        this.$refs.storeModal.openModal()
        this.marketplace_props = {'item': ''}
        this.marketplace_form = shallowRef(marketplaces[marketplace].form)
      }, 100)
    },
    async editStore(marketplace, store_id) {
      this.marketplace_form = ''
      this.modalTitle = 'Редактировать магазин'
      setTimeout(async () => {
        this.$refs.storeModal.openModal()
        const result = await marketplaces[marketplace].api(store_id)
        this.marketplace_props = {'item': result.data}
        this.marketplace_form = shallowRef(marketplaces[marketplace].form)
      })
    },
    async deleteStore(store_id) {
      if (confirm('Вы уверены, что хотите удалить магазин?')) {
        const result = await deleteStore(store_id)
        console.log(result)
        await this.updateStores()
      }
    },
    async storeImport(store_id) {
      if (confirm('Вы уверены, что хотите импортировать товары из магазина?')) {
        const result = await importProducts(store_id)
        console.log(result)
      }
    },
    async storeGetOffers(store_id) {
      if (confirm('Вы уверены, что хотите обновить цены?')) {
        const result = await getOffers(store_id)
        console.log(result)
      }
    },
    async updateStores() {
      const result = await getStores(user.active_company, 1, 10000)
      this.stores = result.data.items
    },
  },
  async mounted() {
    PageInfo.title = 'Магазины'
    PageInfo.breadcrumbs = [
      {
        title: 'Главная',
        link: '/'
      },
      {
        title: PageInfo.title
      }
    ]
    await user.getUserBase()
    await this.updateStores()
  }
}
</script>